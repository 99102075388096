<template>
<main id="main">
  <InspectionSearch/>
</main>
</template>

<script>
import {defineComponent} from '@vue/composition-api'
import InspectionSearch from '../../components/Inspection/InspectionSearch'
import searchInspection from '../../stores/searchInspection'

export default defineComponent({
  setup() {
    const searchInspectionStores = searchInspection()
    return {
      searchInspectionStores
    }
  },
  components : {
    InspectionSearch
  },
  created() {
    this.searchInspectionStores.reset()
  }
})
</script>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
