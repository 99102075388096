<template>
<section id="purchase" class="purchase">
  <div class="wrap1160 purchase-wrap">
    <h1>{{ $t('INSPECTION_LIST_FROM_TITLE') }}</h1>
    <div class="purchase-serach">
      <h2>{{ $t('INSPECTION_PAGE_SEARCH_HEADER') }}</h2>
      <form class="purchase-form">
        <div v-for="text in errors" :key="text"><p class="err-txt">{{ text }}</p></div>
        <div class="purchase-form-wrap">
          <div class="purchase-form-wrap-date">
            <p class="tit">{{ $t('INSPECTION_SEARCH_DATE_LABEL') }}</p>
            <input type="text" v-model="search_condition.request_year_from" class="year full"><span class="sub">年</span>
            <input type="text" v-model="search_condition.request_month_from" class="month full"><span class="sub">月</span>
            <input type="text" v-model="search_condition.request_day_from" class="day full"><span class="sub">日</span>
          </div>
          <div class="from"><p>〜</p></div>
          <div class="purchase-form-wrap-date">
            <input type="text" v-model="search_condition.request_year_to" class="year full"><span class="sub">年</span>
            <input type="text" v-model="search_condition.request_month_to" class="month full"><span class="sub">月</span>
            <input type="text" v-model="search_condition.request_day_to" class="day full"><span class="sub">日</span>
          </div>
        </div>
        <div class="purchase-form-wrap">
          <div class="purchase-form-wrap-box">
            <p class="tit">{{ $t('INSPECTION_SEARCH_REQUEST_NO_LABEL') }}</p>
            <input type="text" v-model="search_condition.manage_no" class="full">
          </div>
          <div class="purchase-form-wrap-box sp_bordert">
            <p class="tit">{{ $t('INSPECTION_SEARCH_TITLE_LABEL') }}</p>
            <input type="text" v-model="search_condition.title" class="full">
          </div>
        </div>
        <div class="purchase-form-wrap">
          <div class="purchase-form-wrap-box full">
            <p class="tit">{{ $t('INSPECTION_SEARCH_CATEGORY_CHECKBOX_LABEL') }}</p>
            <div class="purchase-form-wrap-checkbox">
              <div v-for="(category, i) in selectRequestCategory" :key="i">
                <input
                  :id="'category' + i"
                  type="checkbox"
                  :value="category.value"
                  v-model="search_condition.request_category"
                >
                <label :for="'category' + i">{{ category.label }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="purchase-form-btnWrap">
          <button class="formBtn submit blue" type="submit" @click="search">{{$t("INSPECTION_SEARCH_SEARCH_BUTTON_LABEL")}}</button>
          <a class="purchase-form-absoluteBtn" @click="$routerGo($define.PAGE_NAMES.INSPECTION_REQUEST_PAGE_NAME)">{{$t("INSPECTION_SEARCH_NEW_INSPECTION_REQUEST_BUTTON_LABEL")}}</a>
        </div>
      </form>
    </div>
    <div class="purchase-table table-scroll js-scrollable">
      <table style="height: 100%; table-layout: fixed;">
        <thead>
          <tr>
            <th style="width: 114px;">{{$t("INSPECTION_SEARCH_LIST_REQUEST_NO_FIELD")}}</th>
            <th style="width: 170px;">{{$t("INSPECTION_SEARCH_LIST_TITLE_FIELD")}}</th>
            <th style="width: 120px;">{{$t("INSPECTION_SEARCH_LIST_CATEGORY_FIELD")}}</th>
            <th style="width: 105px;">{{$t("INSPECTION_SEARCH_LIST_DATETIME_FIELD")}}</th>
            <th style="width: 375px;">{{$t("INSPECTION_SEARCH_LIST_STATUS_FIELD")}}</th>
            <th style="width: 275px;">{{$t("INSPECTION_SEARCH_LIST_CONTENT_FIELD")}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in displayList" :key="i">
            <template v-if="item.displayFlag">
              <td v-if="item.firstItem">
                <div class="d-flex align-end flex-column" style="height: 100%;">
                  <div class="mt-auto mb-auto">{{item.manage_no}}</div>
                  <div v-if="item.historyFlag" class="mt-auto mr-auto" style="font-size: 14px;">
                    <label @click="history(item.manage_no)" style="font-weight: bold;">
                      <label v-if="item.openHistoryFlag">▼</label>
                      <label v-else>▶</label>
                      {{$t('INSPECTION_SEARCH_LIST_SHOW_HISTORY_LINK_LABEL')}}
                    </label>
                  </div>
                </div>
              </td>
              <td v-else></td>
              <td v-if="item.firstItem" style="word-wrap: break-word; white-space: normal;">{{item.title}}</td><td v-else></td>
              <td>{{item.request_category_name}}</td>
              <td style="text-align: center;">{{getFormatDate(item.create_datetime)}}<br>{{getFormatTime(item.create_datetime)}}</td>
              <td v-if="item.historyFlag">
                <div>
                  <div v-if="item.judge_status === 1 && item.request_category_id === '1'">
                    <div v-if="nowDate > item.assessment_expired" class="text-red">{{item.status}}（有効期限切れ）</div>
                    <div v-else>{{item.status}}（有効期限 {{item.assessment_expired}}まで）</div>
                    <div>査定価格：{{addComma(item.assessment_amount)}}円</div>
                  </div>
                  <div v-else-if="item.judge_status === 1 && item.request_category_id === '4'">
                    <div v-if="item.cancel_flag === 1" class="text-red">出品取消済</div>
                    <div v-else-if="item.hummer_flag === 1">成約</div>
                    <div v-else-if="item.hummer_flag === 2">流札</div>
                    <div v-else>出品済</div>
                    <span v-if="item.preview_start_datetime">下見開始：{{item.preview_start_datetime}}～<br></span>
                    <span v-if="item.start_datetime || item.end_datetime">入札期間：{{item.start_datetime}}～{{item.end_datetime}}<br></span>
                    <span v-if="item.current_price === null && item.hummer_flag === 0">
                      開始価格：{{addComma(item.lowest_bid_price)}}円（査定額{{addComma(item.assessment_amount)}}円）
                    </span>
                    <span v-else>
                      現在価格：{{addComma(item.current_price)}}円（査定額{{addComma(item.assessment_amount)}}円）
                    </span>
                  </div>
                  <div v-else-if="item.judge_status === 2 && item.request_category_id !== '1'">
                    <div v-if="nowDate > item.assessment_expired" class="text-red">{{item.status}}（修正期限切れ）</div>
                    <div v-else>{{item.status}}（修正期限 {{item.assessment_expired}}まで）</div>
                  </div>
                  <div v-else>
                    {{item.status}}
                  </div>
                </div>
              </td>
              <td v-else>{{item.status}}</td>
              <td>
                <div class="d-flex flex-column btnWrap">
                  <div class="form-inline">
                    <v-badge
                      v-if="item.latest_flag === true"
                      bordered
                      color="error"
                      :content="item.item_chat_un_read_count"
                      :value="item.item_chat_un_read_count > 0 ? true : false"
                      overlap
                    >
                      <a
                        class="btn default blue content-btn"
                        @click="selectItemRequestNo=item.item_request_no; selectManageNo = item.manage_no; $routerGo($define.PAGE_NAMES.CHAT_REQUEST_PAGE_NAME, item.item_request_no)"
                      >依頼チャット</a>
                    </v-badge>
                    <v-badge
                      v-if="item.now_exhibit_flag === true"
                      bordered
                      color="error"
                      :content="item.inquiry_chat_un_read_count"
                      :value="item.inquiry_chat_un_read_count > 0 ? true : false"
                      overlap
                    >
                      <a
                        class="btn default blue content-btn"
                        @click="selectItemRequestNo=item.item_request_no; selectManageNo = item.manage_no; $routerGo($define.PAGE_NAMES.CHAT_INQUIRY_PAGE_NAME, item.exhibition_item_no)"
                      >問合せチャット</a>
                    </v-badge>
                    <v-badge
                      v-if="item.exhibition_division === 1 && item.delivery_category_id !== null && item.hummer_flag === 1"
                      bordered
                      color="error"
                      :content="item.bid_chat_un_read_count"
                      :value="item.bid_chat_un_read_count > 0 ? true : false"
                      overlap
                    >
                      <a
                        class="btn default black"
                        @click="$routerGo($define.PAGE_NAMES.CHAT_BID_PAGE_NAME, item.exhibition_item_no)"
                      >取引チャット</a>
                    </v-badge>
                  </div>
                  <div class="form-inline" style="margin-top: 5px;">
                    <a
                      v-if="(item.latest_flag === true && item.judge_status === 2 && item.request_category_id === '1') || (item.latest_flag === true && item.judge_status === 2 && nowDate < item.assessment_expired)"
                      class="btn default blue content-btn"
                      @click="reRequest(item)"
                    >依頼内容修正</a>
                    <a
                      v-else
                      class="btn default blue content-btn"
                      @click="$routerGo($define.PAGE_NAMES.INSPECTION_AUCTION_REQUEST_PREVIEW, item.item_request_no)"
                    >依頼内容参照</a>
                  </div>
                  <div class="form-inline" style="margin-top: 5px;">
                    <a
                      v-if="item.latest_flag === true && item.request_category_id === '1' && item.judge_status === 1 && nowDate <= item.assessment_expired"
                      class="btn default yellow content-btn"
                      @click="selectItemRequestNo=item.item_request_no; selectManageNo = item.manage_no; $routerGo($define.PAGE_NAMES.INSPECTION_PURCHASE_REQUEST_PAGE_NAME)"
                    >買取依頼</a>
                    <a
                      v-if="item.latest_flag === true && item.request_category_id === '1' && item.judge_status === 1 && item.exhibition_allow_flag === 1 && nowDate <= item.assessment_expired"
                      class="btn default orange content-btn"
                      @click="selectItemRequestNo=item.item_request_no; selectManageNo = item.manage_no; $routerGo($define.PAGE_NAMES.INSPECTION_AUCTION_REQUEST_PAGE_NAME)"
                    >出品依頼</a>
                    <a v-if="item.can_delete === true" class="btn default red content-btn" @click="openCancelExhibitDialog(item.exhibition_item_no)">出品取消</a>
                  </div>
                </div>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <PopupCancelExhibit
    :exhibition-item-no="cancelExhibitionItemNo"
    :is-open-dialog="isOpenCancelExhibitDialog"
    :close-dialog="() => {isOpenCancelExhibitDialog=false; search()}"
  />
</section>
</template>

<script>
import '@/assets/scss/scroll-hint.scss'
import inspectionAuctionRequest from '@/stores/inspectionAuctionRequest'
import searchInspection from '@/stores/searchInspection'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'
import ScrollHint from 'scroll-hint'
import PopupCancelExhibit from './parts/PopupCancelExhibit.vue'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const inspectionStore = searchInspection()
    const auctionRequestStore = inspectionAuctionRequest()
    const selectRequestCategory = inspectionStore.selectRequestCategory
    return {
      selectRequestCategory,
      loadingStore,
      inspectionStore,
      auctionRequestStore
    }
  },
  data() {
    return {
      isOpenCancelExhibitDialog : false,
      cancelExhibitionItemNo    : null,
    }
  },
  computed : {
    search_condition() {
      return this.inspectionStore.search_condition
    },
    displayList() {
      return this.inspectionStore.displayList
    },
    requestUnCheckedCntData() {
      return this.inspectionStore.requestUnCheckedCntData
    },
    inquiryUnCheckedCntData() {
      return this.inspectionStore.inquiryUnCheckedCntData
    },
    bidUnCheckedCntData() {
      return this.inspectionStore.bidUnCheckedCntData
    },
    nowDate() {
      return this.inspectionStore.nowDate
    },
    errors() {
      return this.inspectionStore.errors
    },
    selectManageNo : {
      get() {
        return this.auctionRequestStore.selectManageNo
      },
      set(val) {
        this.auctionRequestStore.selectManageNo = val
      }
    },
    selectItemRequestNo : {
      get() {
        return this.auctionRequestStore.selectItemRequestNo
      },
      set(val) {
        this.auctionRequestStore.selectItemRequestNo = val
      }
    },
    selectExhibitionItemNo : {
      get() {
        return this.auctionRequestStore.selectExhibitionItemNo
      },
      set(val) {
        this.auctionRequestStore.selectExhibitionItemNo = val
      }
    }
  },
  methods : {
    search(event) {
      event?.preventDefault()
      this.inspectionStore.search(this.$i18n.locale)
    },
    history(manageNo) {
      this.inspectionStore.history(manageNo)
    },
    getFormatDate(datetime) {
      return this.inspectionStore.getFormatDate(datetime)
    },
    getFormatTime(datetime) {
      return this.inspectionStore.getFormatTime(datetime)
    },
    addComma(price) {
      return this.inspectionStore.addComma(price)
    },
    openCancelExhibitDialog(val) {
      console.log('openCancelExhibitDialog', val)
      this.cancelExhibitionItemNo = val
      this.isOpenCancelExhibitDialog = true
    },
    reRequest(item) {
      console.log('request_category_id: ', item.request_category_id)
      if (item.request_category_id === '1') {
        // 査定依頼の場合
        this.$routerGo(this.$define.PAGE_NAMES.INSPECTION_AUCTION_REQUEST_EDIT, item.item_request_no)
      } else if (item.request_category_id === '2') {
        // 買取依頼の場合
        this.selectItemRequestNo = item.item_request_no
        this.selectManageNo = item.manage_no
        this.$routerGo(this.$define.PAGE_NAMES.INSPECTION_PURCHASE_REQUEST_PAGE_NAME)
      } else if (item.request_category_id === '3' || item.request_category_id === '4') {
        // 出品依頼、出品代行の場合
        this.selectItemRequestNo = item.item_request_no
        this.selectManageNo = item.manage_no
        this.$routerGo(this.$define.PAGE_NAMES.INSPECTION_AUCTION_REQUEST_PAGE_NAME)
      }
    }
  },
  watch : {
    '$i18n.locale'() {
      this.search()
    },
    errors(value) {
      const keys = Object.keys(value)
      if (keys.length > 0) {
        const refkey = keys[0]
        if (value[keys[0]] && this.$refs[refkey]) {
          this.$refs[refkey].focus()
        }
      }
    }
  },
  updated() {
    // 携帯でみると、査定・出品の内容はスワイプしながら少しスワイプできることをわかりやすく表示。
    new ScrollHint('.js-scrollable', {
      suggestiveShadow : false,
      i18n             : {
        scrollable : 'スクロールできます'
      }
    })
  },
  components : {PopupCancelExhibit}
})
</script>

<style scoped lang="scss">
.form-inline > .v-badge {
  display: inline-block !important;
  margin-right: 10px !important;
}
.form-inline > a {
  display: inline-block !important;
}
.content-btn {
  width: 115px !important;
  padding: 10px !important;
}
.err-txt {
  font-size: 16px !important;
}

.preparing {
  text-align: center;
  color: #364A81;
  font-size: large;
  font-weight: 600;
}

.purchase-form-absoluteBtn.disabled {
  background-color: grey !important;
}
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
