import router from '@/router'
import {defineStore} from 'pinia'
import Methods from '../api/methods'
import useLoading from './useLoading'
const loadingStore = useLoading()

const searchInspection = defineStore('searchInspection', {
  state : () => {
    return {
      // Locale: 'ja',
      search_condition       : {
        request_year_from  : null,
        request_month_from : null,
        request_day_from   : null,
        request_year_to    : null,
        request_month_to   : null,
        request_day_to     : null,
        manage_no          : null,
        title              : null,
        request_category   : []
      },
      displayList             : [],
      errors                  : {},
      historyFlag             : false,
      nowDate                 : Methods.getFormatDate(new Date()),

      // Temporary
      saveTemporaryComplete     : false,
      itemRequestTemporary      : null,
      itemRequestFilesTemporary : null,

      // Constants
      selectRequestCategory : [
        {value : 1, label : '査定依頼'},
        {value : 2, label : '買取依頼'},
        {value : 3, label : '出品代行依頼'},
        {value : 4, label : 'オークション出品依頼'},
      ],
    }
  },
  getters : {
  },
  actions : {
    reset() {
      this.search_condition.request_year_from = null
      this.search_condition.request_year_to = null
      this.search_condition.request_month_from = null
      this.search_condition.request_month_to = null
      this.search_condition.request_day_from = null
      this.search_condition.request_day_to = null
      this.search_condition.title = null
      this.search_condition.manage_no = null
      this.search_condition.request_category = []
      this.displayList = []
      this.errors = {}

      this.search()
    },
    search(locale = 'ja') {
      console.log('search')
      this.errors = {}
      this.displayList = []
      const params = {
        requestYearFrom  : this.search_condition.request_year_from ? this.search_condition.request_year_from : null,
        requestMonthFrom : this.search_condition.request_month_from ? this.search_condition.request_month_from : null,
        requestDayFrom   : this.search_condition.request_day_from ? this.search_condition.request_day_from : null,
        requestYearTo    : this.search_condition.request_year_to ? this.search_condition.request_year_to : null,
        requestMonthTo   : this.search_condition.request_month_to ? this.search_condition.request_month_to : null,
        requestDayTo     : this.search_condition.request_day_to ? this.search_condition.request_day_to : null,
        manageNo         : this.search_condition.manage_no ? this.search_condition.manage_no : null,
        title            : this.search_condition.title ? this.search_condition.title : null,
        requestCategory  : this.search_condition.request_category
      }
      console.log('params:', params)
      loadingStore.setLoading(true)
      this.errors = {}
      Methods.apiExcute('private/search-inspection-items', locale, params).then(data => {
        if (typeof data === 'undefined' || data === null) {
          this.displayList = []
        } else {
          const tmpMap = new Map()
          for (let i = 0;i < data.length;i++) {
            const items = data.filter(x => x.manage_no === data[i].manage_no)
            tmpMap.set(String(data[i].manage_no), items)
          }
          for (const key of tmpMap.keys()) {
            const items = tmpMap.get(String(key))
            if (items.length > 0) {
              items.forEach((item, j) => {
                if (j === 0) {
                  item.displayFlag = true
                  item.historyFlag = items.length > 1
                  item.firstItem = true
                  item.openHistoryFlag = false
                } else {
                  item.displayFlag = false
                  item.historyFlag = false
                  item.firstItem = false
                }
                this.displayList.push(item)
              })
            }
          }
        }
        loadingStore.setLoading(false)
      })
        .catch(error => {
          loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(router, error)
        })
    },
    history(manageNo) {
      const firstItem = this.displayList.find(detail => {
        return detail.manage_no === manageNo && detail.firstItem === true
      })
      const openHistoryFlag = firstItem.openHistoryFlag
      firstItem.openHistoryFlag = !openHistoryFlag
      this.displayList.filter(detail => {
        return detail.manage_no === manageNo && detail.firstItem === false
      }).forEach(x => {
        x.displayFlag = !openHistoryFlag
      })
    },
    getFormatDate(datetime) {
      return Methods.getFormatDate(datetime)
    },
    getFormatTime(datetime) {
      return Methods.getFormatTime(datetime)
    },
    addComma(price) {
      return Number(price).toLocaleString()
    },
  },
})

export default searchInspection
