<template>
  <v-dialog v-model="isOpenDialog" persistent content-class="popup" style="z-index=1001;" width="460px">
    <v-card style="border-radius: 1rem;">
      <v-card-text class="border-bottom mt-5 popup popup-fileup" style="display: block;">
        <div class="popup-wrap small">
          <a class="popup-close js-close">
            <img @click.stop="closeDialog" src="@/assets/img/common/ic_close.svg" alt="">
          </a>
          <div v-if="errors && errors.message" class="popup-content">
            <div class="popup-content-form">
              <p class="text-center text-red">{{ errors.message }}<br></p>
            </div>
            <div class="popup-content-form-btnWrap">
              <v-btn @click.stop="closeDialog" color="red" class="white--text">{{$t("CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_CANCLE_LABEL")}}</v-btn>
            </div>
          </div>
          <div v-else class="popup-content">
            <div class="popup-content-form">
              <p class="text-center">{{ $t('INSPECTION_AUCTION_CANCEL_REQUEST_TEXT') }}</p>
              <p class="text-center">{{ $t('INSPECTION_AUCTION_CANCEL_REQUEST_2_TEXT') }}</p>
            </div>
            <div class="popup-content-form-btnWrap">
              <v-btn @click.stop="closeDialog" color="red" class="white--text">{{$t("CHANGE_INFO_WITHDRAW_MEMBER_COMFIRM_DIALOG_CANCLE_LABEL")}}</v-btn>
              <v-btn @click="request" color="#364A81" class="white--text">{{$t("INSPECTION_REQUEST_COMFIRM_DIALOG_OK_LABEL")}}</v-btn>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
  </template>

<script>
import Methods from '@/api/methods'
import CommonFilters from '@/common/commonFilters'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    return {
      loadingStore,
    }
  },
  components : {},
  mixins     : [CommonFilters],
  props      : {
    isOpenDialog : {
      type    : Boolean,
      default : false
    },
    closeDialog : {
      type    : Function,
      default : Object
    },
    exhibitionItemNo : {
      type    : Number
    }
  },
  data() {
    return {
      errors : {}
    }
  },
  methods : {
    request() {
      console.log('cancel exhibition', this.exhibitionItemNo)
      this.errors = {}
      const params = {
        exhibitionItemNo    : this.exhibitionItemNo,
      }
      this.loadingStore.setLoading(true)
      Methods.apiExcute('private/request-exhibit-item-cancel', this.$i18n.locale, params).then(data => {
        this.loadingStore.setLoading(false)
        this.closeDialog()
      })
        .catch(error => {
          this.loadingStore.setLoading(false)
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    }
  },
})
</script>
  <style lang="scss" scoped>
    .popup-content-form-btnWrap button {
      margin: 0 20px;
    }
    .text-red {
      color: red;
    }
  </style>
  <style scoped lang="scss" src="@/assets/scss/style.scss"></style>
